@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root {
  ul {
    list-style: none;
  }

  a {
    color: white;
    text-decoration: none;
  }

  li {
    margin-bottom: 15px;
  }
}

.title {
  margin-bottom: 15px;
  line-height: 2rem;
  font-size: 1em;
  font-weight: bold;
  font-stretch: condensed;
  display: flex;
  align-items: center;
}

.arrowIcon {
  display: initial;
  margin-left: auto;

  @media screen and (min-width: vars.$large) {
    display: none;
  }

  svg {
    width: 10px;
  }
}

.hideLinkContainer {
  display: none;
  @media screen and (min-width: vars.$large) {
    display: initial;
  }
}
