@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.newsletterSection {
  background-color: colors.$primary-color;
  color: colors.$white;
  padding: 30px 0 20px 0;
  contain: content;

  @media screen and (min-width: vars.$large) {
    padding: 60px 0;
  }
}

.newsletterTitle {
  font-size: 1.375rem;
  font-weight: bold;
  font-stretch: condensed;
  line-height: 2rem;

  @media screen and (min-width: vars.$medium) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: vars.$extra-large) {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
}

.newsletterTagLine {
  margin-top: 5px;

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 15px;
  }
}

.newsletterUsps {
  margin-top: 10px;

  @media screen and (min-width: vars.$medium) {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 20px;
  }
}

.newsletterUsps li {
  display: none;

  @media screen and (min-width: vars.$extra-extra-large) {
    display: flex;
    align-items: center;
    font-style: italic;
    justify-content: center;
    margin-top: 10px;
    margin-right: 30px;
  }
}

.newsletterUsps li:last-child {
  @media screen and (min-width: vars.$large) {
    margin-right: 0;
  }
}

.newsletterButton {
  margin-top: 15px;
  margin-bottom: 20px;

  &:hover {
    background-color: colors.$white;
    color: colors.$primary-color;
  }

  @media screen and (min-width: vars.$large) {
    margin-top: 30px;
    width: 340px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    margin-bottom: 0;
  }
}

.left {
  display: none;

  p {
    opacity: 0.5;
  }

  @media screen and (min-width: vars.$large) {
    display: block;
  }
}

.right {
  display: none;

  .caption {
    margin-bottom: 15px;
    line-height: 2rem;
    font-size: 1em;
    font-weight: bold;
    font-stretch: condensed;
  }

  ul {
    list-style: none;
  }

  a {
    color: white;
    text-decoration: none;
  }

  li {
    margin-bottom: 15px;
  }

  @media screen and (min-width: vars.$large) {
    display: block;
  }
}

.left svg {
  opacity: 0.5;
}

.image {
  margin-bottom: 20px;

  svg {
    opacity: 0.5;
    display: block;
  }

  p {
    opacity: 0.5;

    @media screen and (min-width: vars.$medium) {
      display: inline-block;

      &:first-of-type {
        margin-right: 5px;
      }
    }

    @media screen and (min-width: vars.$large) {
      display: block;
      &:first-of-type {
        margin-right: 0px;
      }
    }
  }
}

.footerLinks {
  width: 100%;
  text-align: left;

  .caption {
    line-height: 2rem;
  }

  @media screen and (min-width: vars.$large) {
    display: none;
    margin-top: 0;
  }
}

.footerLinks .caption,
.categoryList .caption,
.accountList .caption {
  margin-bottom: 14px;
  position: relative;
  font-weight: bold;
  font-stretch: condensed;
}

.footerLinks .caption span,
.categoryList .caption span,
.accountList .caption span {
  position: absolute;
  right: 0;
  transition: 0.2ms all ease-in-out;
  font-size: 0.6em;
}

.footerLinks input,
.categoryList input,
.accountList input {
  position: absolute;
  display: inline-block;
  width: 100%;
  margin-top: 0;
  opacity: 0;
  z-index: 1;
  height: 30px;
}

.footerLinks ul,
.categoryList ul,
.accountList ul {
  display: none;
  margin-bottom: 5px;
  @media screen and (min-width: vars.$large) {
    display: block;
  }
}

.footerLinks li,
.categoryList li,
.accountList li {
  list-style-type: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 14px;
  @media screen and (min-width: vars.$large) {
    padding: 0;
  }
}

.footerLinks a,
.categoryList a,
.accountList a {
  text-decoration: none;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: colors.$white;
}

.footerLinks li:hover a {
  color: colors.$primary-color;
}

.footerLinks input:checked ~ ul,
.categoryList input:checked ~ ul,
.accountList input:checked ~ ul {
  display: block;
}

.footerLinks input:checked ~ .caption span,
.categoryList input:checked ~ .caption span,
.accountList input:checked ~ .caption span {
  transform: rotate(180deg);
}

.listContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media screen and (min-width: vars.$large) {
    flex-direction: row;

    .footerLinks {
      display: none;
    }
  }

  ul {
    list-style: none;
  }

  .caption {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1rem;
    font-stretch: condensed;
  }

  a {
    color: colors.$white;
    text-decoration: none;
  }

  li {
    margin-bottom: 15px;
  }

  .categoryList {
    @media screen and (min-width: vars.$large) {
      margin-right: 30px;
    }
  }
}
